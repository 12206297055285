<template>
    <div>
        <v-sheet rounded="sm" class="trader-section">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="12">
                        <h2> Trader {{trader_id}}</h2>
                        <div style="width:100%;">

                            <center>

                                <trader-link-to-page :traderId="trader_id"></trader-link-to-page>
                            </center>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-tabs centered color="white" >
                                <v-tab>Data</v-tab>
                                <v-tab>Trades</v-tab>
                                <v-tab>Similar Traders</v-tab>
                                <v-tab-item>
                                    <v-container fluid>
                                        
                                        <v-row>
                                            <v-col sm="12" md="12">
                                                <h4>Scores</h4>
                                                <trader-standard-score-sheet :traderId="trader_id" ></trader-standard-score-sheet>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <h4>counts</h4>
                                                
                                                <div v-if="trader_counts">
                                                    <table>
                                                        <tr> <th>tokens bought</th> <td>{{trader_counts.counts[trader_id].tokens_bought}}</td> </tr>
                                                        <tr> <th>tokens sold</th> <td>{{trader_counts.counts[trader_id].tokens_sold}}</td> </tr>
                                                        <tr> <th>total trades</th> <td>{{trader_counts.counts[trader_id].total_trades}}</td> </tr>
                                                        <tr> <th>trades per token bought</th> <td>{{trader_counts.counts[trader_id].trades_per_btoken}}</td> </tr>
                                                        <tr> <th>tokens bought per token sold</th> <td>{{trader_counts.counts[trader_id].btokens_per_stokens}}</td> </tr>
                                                    </table>

                                                </div>
                                                <v-btn v-else class="ma-0"  elevation="2" small block @click="do_get_trader_counts" > get trader counts</v-btn>
                                                
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <h4>Activity</h4>
                                                <table v-if="active_for">
                                                    <tr><th>First  seen     </th> <td> <time-display :time="active_for.first_seen" :mode="'time'"></time-display></td></tr>
                                                    <tr><th>Last   seen     </th> <td> <time-display :time="active_for.last_seen" :mode="'time'"></time-display></td></tr>
                                                    <tr><th>Last   seen ago </th> <td> <time-display :time="active_for.last_seen_ago" :mode="'timedelta'"></time-display></td></tr>
                                                    <tr><th>Active for      </th> <td> <time-display :time="active_for.active_for_seconds" :mode="'timedelta'"></time-display></td></tr>
                                                </table>
                                                <v-btn v-else class="ma-0"  elevation="2" small block @click="do_get_trader_active_for" >do_get_trader_active_for</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                                <!-- trades  -->
                                <v-tab-item>
                                    <v-btn class="ma-1"  elevation="2" small  @click="do_get_trader_trades" >get trades</v-btn>
                                    <v-btn class="ma-1"  elevation="2" small @click="do_force_recalc_trades" v-if="miscalculated_trader_ids.length != 0">force calculate price amounts ({{miscalculated_trader_ids.length}})</v-btn>
                                    <table v-if="trader_trades">
                                        <tr>
                                            <!-- <th></th> -->
                                            <th>date</th>
                                            <th></th>
                                            <th>price standard </th>
                                            <th>token</th>
                                            <th>amount</th>
                                            <th>hash</th>
                                            <th>gas price</th>
                                        </tr>
                                        <tr v-for="trade in trader_trades.trades" :key="trade.id">
                                            <!-- <td>{{trade.id}}</td> -->
                                            <td> <time-display :time="trade.time_epoch"></time-display> </td>
                                            <td> {{trade.direction}}</td>
                                            <td> <base-amount :amount="trade.pair_standard_price"></base-amount> </td>
                                            <td>
                                                <div v-if="trade.direction == 'buy'">
                                                    <pair-link-to-page :pair="trader_trades.pairs[trade.token_out__id]"></pair-link-to-page>
                                                </div>
                                                <div v-if="trade.direction == 'sell'">
                                                    <pair-link-to-page :pair="trader_trades.pairs[trade.token_in__id]"></pair-link-to-page>
                                                </div>
                                            </td>
                                            <td> <base-amount :amount="trade.amount_base"></base-amount></td>
                                            <td> <external-link-etherscan :hash="trade.hash" :type="'tx'"></external-link-etherscan></td>
                                            <td> {{(trade.gas_price * 1e-9).toFixed(1)}}</td>
                                        </tr>

                                    </table>
                                </v-tab-item>
                                <v-tab-item>
                                    (no groups yet)
                                </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-container fluid v-if="old_mode">
            <v-row>
                <!-- <v-col cols="12" md="6">
                    <v-sheet class="trader-section" rounded="sm" v-if="trade_metrics">
                    <h3> Scores </h3>


                    <table class="scores-table">
                            <tr>
                                <th>Value</th>
                                <th>Score</th>
                            </tr>
                            <tr> <th> score buyer </th> <td v-if="trade_metrics.score.score_buyer" > {{ trade_metrics.score.score_buyer.toFixed(2) }} </td> </tr>
                            <tr> <th> score seller </th> <td v-if="trade_metrics.score.score_seller" > {{ trade_metrics.score.score_seller.toFixed(2) }} </td> </tr>
                            <tr> <th> score blsh </th> <td v-if="trade_metrics.score.score_blsh" > {{ trade_metrics.score.score_blsh.toFixed(2) }} </td> </tr>
                            <tr> <th> Human Score </th> <td v-if="trade_metrics.score.score_daily_std" > {{ (trade_metrics.score.score_daily_std *200).toFixed(1) }} % </td> </tr>
                            <tr> <th> early bird </th> <td> 
                                <div v-if="trade_metrics.score.score_avg_early_bird">
                                    {{ trade_metrics.score.score_avg_early_bird .toFixed(0)}}<sup>th</sup>  on avg 
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </td> </tr>
                            <tr> <th> First Seen </th> <td> {{ epochToFormattedDate(trade_metrics.score.first_seen) }} </td> </tr>
                            <tr> <th> Last Seen </th> <td> {{ epochToFormattedDate(trade_metrics.score.last_seen) }} </td> </tr>
                            <tr> <th> Last Seen ago </th> <td> {{ trade_metrics.score.last_seen_ago.split('.')[0] }} </td> </tr>
                            <tr> <th> Active For </th> <td> {{ trade_metrics.score.active_for_str }} </td> </tr>
                            <tr> <th> Total Trades </th> <td> {{ trade_metrics.score.score_trade_count }} </td> </tr>
                        </table>
                    </v-sheet>
                </v-col> -->
                <v-col cols="12" md="12"><v-sheet class="trader-section" rounded="sm">
                    <v-container fluid>
                        <v-row>
                            <v-col sm="12" md="12">
                            <h2> Trader {{ $route.params.trader_id  }}</h2>
                                
                            <h5>Daily Activity</h5>
                            <v-sparkline :value="trade_metrics.binned_times.map(x=>x.count)" :gradient="['#f72047', '#ffd200', '#1feaea']" :smooth="10 || false" :padding="8" :line-width="2" :stroke-linecap="'round'" :gradient-direction="'top'" :fill="false" :type="'trend'" :auto-line-width="false" auto-draw ></v-sparkline>
                            
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-tabs centered color="white" >
                                        <v-tab>investments</v-tab>
                                        <v-tab>similar trades</v-tab>
                                        <v-tab> buys / sells</v-tab>
                                        <v-tab-item>
                                            <table class="buy_sell_summary" v-if="trade_metrics">
                                                <tr>
                                                    <th>last</th>
                                                    <th>token</th>
                                                    <th>buys</th>
                                                    <th>sells</th>
                                                    <!-- <th>bhsl</th> -->
                                                    <th>p/l closed</th>
                                                </tr>
                                                <tbody  v-for="tdata in trade_metrics.buy_sell_summary" :key="tdata.token_id" >

                                                    <tr>
                                                            <td>
                                                                <time-display :time=tdata.stats.last_trade></time-display>
                                                            </td>
                                                            <td>
                                                                <!-- {{ tdata.token_id }} -->
                                                                <pair-link-to-page :pair="trade_metrics.tokens[tdata.token_id]" :vlines="get_trade_vlines(tdata.token_id)"></pair-link-to-page>
                                                            </td>
                                                            <td>
                                                                <div style="font-weight:bold;font-size:1.15em"> {{ tdata.stats.buy_cnt }} </div >

                                                            </td>
                                                            <td>
                                                                <div style="font-weight:bold;font-size:1.15em"> {{ tdata.stats.sell_cnt }} </div>
                                    
                                                            </td>
                                                            <!-- <td>
                                                                <div style="display:flex; justify-content: center;">
                                                                    <div v-if="tdata.stats.blsh  == 0"> - </div>
                                                                    <div v-else-if="tdata.stats.blsh < 0.1"> <div class="color-indicator" style="background-color: red;">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                    <div v-else-if="tdata.stats.blsh < 0.5"> <div class="color-indicator" style="background-color: hsl(15, 100%, 50%);">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                    <div v-else-if="tdata.stats.blsh < 0.9"> <div class="color-indicator" style="background-color: rgb(177, 122, 122);">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                    <div v-else-if="tdata.stats.blsh < 1.2"> <div class="color-indicator" style="background-color: rgb(102, 102, 102);">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                    <div v-else-if="tdata.stats.blsh < 1.7"> <div class="color-indicator" style="background-color: hsl(73, 42%, 45%);">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                    <div v-else-if="tdata.stats.blsh > 1.7"> <div class="color-indicator" style="background-color: hsl(118, 42%, 45%);">{{ tdata.stats.blsh.toFixed(1)  }} x</div> </div>
                                                                </div>
                                                            </td> -->
                                                            <td>
                                                                <div>
                                                                    <base-amount :amount="tdata.stats.total_closed"></base-amount>
                                                             
                                                                </div>
                                                            </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"> </td>
                                                        <td> <div style="color:gray" v-if="tdata.stats.buy_cnt !=0" >{{ x_to_price_impact(tdata.stats.avg_buy_1h_inc) }}</div>  </td>
                                                        <td  > <div  style="color:gray" v-if="tdata.stats.sell_cnt !=0"> {{ x_to_price_impact(tdata.stats.avg_sell_1h_dec) }}</div>  </td>
                                                        <td colspan="2"> </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <h3> similar traders</h3>
                                            <intel-trader-related-traders :traderId="$route.params.trader_id"></intel-trader-related-traders>
                                        </v-tab-item>
                                        <v-tab-item  v-if="trade_metrics">
                                            <v-tabs centered color="white" >
                                                    <v-tab>buy</v-tab>
                                                    <v-tab>sell</v-tab>
                                                    <v-tab-item>
                                                        <v-sheet rounded="sm">
                                                            <h3>Buyer</h3>
                                                            <table>
                        
                                                                <!-- <tr>
                                                                    <td colspan="2"><small>Score</small></td>
                                                                    <th colspan="3"><h2>{{ trade_metrics.score.score_buyer.toFixed(0) }}</h2></th>
                                                                </tr> -->
                                                                <tr>
                                                                    <th></th>
                                                                    <th>token</th>
                                                                    <th>pct bought</th>
                                                                    <th>amt</th>
                                                                    <th>Mx inc</th>
                                                                </tr>
                                                                                        
                                                                <tbody class="summary-section">
                                                                    <tr>
                                                                        <th>Mean</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td style="font-size:1.5em" > <base-amount :amount="trade_metrics.buy_summary.amount.mean"></base-amount> </td>
                                                                        <td>{{ trade_metrics.buy_summary.price_x.mean.toFixed(2) }}</td>
                                                                    </tr>
                                                                    
                                                                        <tr>
                                                                        <th>Median</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><base-amount :amount="trade_metrics.buy_summary.amount.median"></base-amount></td>
                                                                        <td>{{ trade_metrics.buy_summary.price_x.median.toFixed(2) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Std</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td> <base-amount :amount="trade_metrics.buy_summary.amount.std"></base-amount></td>
                                                                        <td>{{ trade_metrics.buy_summary.price_x.std.toFixed(2) }}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tr>
                                                                    <td colspan="5">&nbsp;</td>
                                                                </tr>
                                                                <tr v-for="t in trade_metrics.buy_trades" :key="t.id" >
                                                                    <td>{{ epochToFormattedDate(t.time_epoch) }}</td>
                                                                    <td style="display:flex;"> 
                                                                        <pair-link-to-page :pair="trade_metrics.tokens[t.token_out__id]" :vlines="get_trade_vlines(t.token_out__id)"></pair-link-to-page>
                                                                        <small style="color:gray">({{ t.th_buyer }}<sup>th</sup>)</small>
                                                                    </td>
                                                                    <td> {{ (t.amount_liq *100).toFixed(2)  }} % </td>
                                                                    <td> <base-amount :amount="t.amount_base"></base-amount> </td>
                                                                
                                                                    <td>
                                                                        <div v-if="t.pair_price_post_one_hour_max ">
                                                                            <span v-if="t.pair_price_post_one_hour_max != 1"> {{ t.pair_price_post_one_hour_max.toFixed(2) }} </span>
                                                                            <span v-else> F </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5">&nbsp;</td>
                                                                </tr>

                                                                
                                                                
                                                            </table>
                                                        </v-sheet>
                                                    </v-tab-item>
                                                    <v-tab-item  v-if="trade_metrics" >
                                                        <v-sheet rounded="sm">
                                                            <h3>Seller</h3>
                                                            <table>
                                                                <tr>
                                                                    <td colspan="2"><small>Score</small></td>
                                                                    <th colspan="3"><h2>{{ trade_metrics.score.score_seller.toFixed(0)  }}</h2></th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5">&nbsp;</td>
                                                                </tr>
                                                                
                                                                <tr>
                                                                    <th>time</th>
                                                                    <th>token</th>
                                                                    <th>pct Sold</th>
                                                                    <th>amt</th>
                                                                    <th>Mx Dec</th>
                                                                </tr>
                                                                <tr v-for="t in trade_metrics.sell_trades" :key="t.id">
                                                                    <td>{{ epochToFormattedDate(t.time_epoch) }}</td>
                                                                    <td> 
                                                                        <pair-link-to-page :pair="trade_metrics.tokens[t.token_in__id]" :vlines="get_trade_vlines(t.token_in__id)"></pair-link-to-page>
                                                                    </td>
                                                                    <td>
                                                                        {{ (t.amount_liq *100).toFixed(2)  }} %
                                                                    </td>
                                                                    <td>
                                                                        <base-amount :amount="t.amount_base"></base-amount>
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="t.pair_price_post_one_hour_min">
                                                                            <span v-if="t.pair_price_post_one_hour_min != 1"> {{ t.pair_price_post_one_hour_min.toFixed(2) }} x</span>
                                                                            <span v-else> F </span>

                                                                        </div>
                                                                    </td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="4">&nbsp;</td>
                                                                </tr>
                                                                <tbody class="summary-section">
                                                                    <tr>
                                                                        <th>Mean</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td> <base-amount :amount="trade_metrics.sell_summary.amount.mean"></base-amount> </td>
                                                                        <td>{{ trade_metrics.sell_summary.price_x.mean.toFixed(2) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Median</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><base-amount :amount="trade_metrics.sell_summary.amount.median"></base-amount></td>
                                                                        <td>{{ trade_metrics.sell_summary.price_x.median.toFixed(2) }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Sd</th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td> <base-amount :amount="trade_metrics.sell_summary.amount.std"></base-amount></td>
                                                                        <td>{{ trade_metrics.sell_summary.price_x.std.toFixed(2) }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </v-sheet>
                                                    </v-tab-item>
                                            </v-tabs>
            
                                        </v-tab-item>
                                </v-tabs>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                    
                    



                    </v-sheet>
                </v-col>
                <v-col cols="12" md="12">
                    <v-container fluid v-if="trade_metrics">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-sheet rounded="sm">
                               
                                </v-sheet>
                            </v-col>
                        </v-row>
                        <v-row v-if=" ! score_is_calculated | loading.scores">
                            <v-col sm="12" md="12">
                                <v-sheet rounded="sm">
                                    <center>

                                        Score For trader {{ $route.params.trader_id  }} has not been calculated yet... 
                                        <br> Calculating now... ( ~ {{ (0.00531* (trade_metrics.buy_trades.length + trade_metrics.sell_trades.length)).toFixed(1) }} min)
                                        <br>
                                        <v-progress-circular class="ma-3" indeterminate color="white"></v-progress-circular>
                                    </center>
                                </v-sheet>
                            </v-col>
                        </v-row>
     
                        <v-row >
                            <v-col sm="12" md="12" v-if="! (trade_metrics.score.score_trade_count == (trade_metrics.buy_trades.length + trade_metrics.sell_trades.length))">
                                    <v-btn :disabled="loading.scores" class="ma-0" color="red" elevation="2" small  block @click="do_trader_trade_metrics('true')" > New Data available. Recalculate here</v-btn>
                                </v-col>

                        </v-row>

                    </v-container>

                    <!-- <pre>
                        {{ trade_metrics }}
                    </pre> -->
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import BaseAmount from './base-amount.vue'
import IntelTraderRelatedTraders from './intel/intel-trader-related-traders.vue'
import PairLinkToPage from './pair-link-to-page.vue'
import TimeDisplay from './time-display.vue'
import TraderStandardScoreSheet from './trader-standard-score-sheet.vue'
import ExternalLinkEtherscan from './external-link-etherscan.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderLinkToPage from './trader-link-to-page.vue'
export default {
    name: 'view-trader',
    components:{
        PairLinkToPage,
        BaseAmount,
        IntelTraderRelatedTraders,
        TimeDisplay,
        ExternalLinkEtherscan,  
        TraderStandardScoreSheet,
        TraderLinkToPage,
    },
    emits:[
  ],
    data(){return {
        trade_metrics:undefined,
        score_is_calculated:true,
        loading:{
            scores:false,
        },
        old_mode:false,


        trader_id:undefined,
        trader_scores:undefined,
        trader_counts:undefined,
        active_for:undefined,
        trader_trades:undefined,
        trader_similarity_groups:undefined,
        trader_score_processed:undefined,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.trader_id = this.$route.params.trader_id
        this.do_get_trader_active_for()
        this.do_get_trader_counts()
        if(this.old_mode){
            this.do_trader_trade_metrics('false')

        }

    
    },
    methods:{
        ...mapActions(['trader_trade_metrics','get_trader_score','traders_calc_list_counts','trader_page_get_views','force_recalc_trades']),
        do_trader_trade_metrics(get_missing_metrics){
            this.loading.scores = true
            this.trader_trade_metrics({qs:{trader_id:this.$route.params.trader_id, get_missing_metrics:get_missing_metrics}}).then(r=>{
                this.trade_metrics = r.data
                this.loading.scores = false
                if(r.data.buy_summary.price_x.mean == 0){
                    this.score_is_calculated = false
                    this.do_trader_trade_metrics('true')
                    console.log("This is an empty dataset... calling get missing now ")
                    
                }else{
                    this.score_is_calculated = true
                }
            })
        },
        x_to_price_impact(pi){
            let price_impact =  (pi -1)*100
            if(! price_impact){return '-'}

            let precursor = '▲'
            if (price_impact < 0){ precursor = '▼'}
            return `${precursor} ${price_impact.toFixed(2)} %`

        },
        epochToFormattedDate(epoch) {
          const date = new Date(epoch * 1000);
          // Extract day, month, hours, and minutes from the date object
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}-${month} ${hours}:${minutes}`;
        },
        get_trade_vlines(token_id){

            const buy_v_lines = this.trade_metrics.buy_trades.filter(tr => tr.token_out__id == token_id).map(tr => {
                return {name:`Buy ${tr.amount_base.toFixed(4)}`, 'time': tr.time_epoch, 'color':'green'}
            })
            const sell_v_lines = this.trade_metrics.sell_trades.filter(tr => tr.token_in__id == token_id).map(tr => {
                return {name:`Sell ${tr.amount_base.toFixed(4)}`, 'time': tr.time_epoch, 'color':'red'}
            })
            const v_lines = buy_v_lines.concat(sell_v_lines)
            return v_lines

        },




        do_get_trader_counts(){
            this.traders_calc_list_counts({qs:{},data:{trader_ids:[this.trader_id]}}).then((r)=>{
                this.trader_counts = r.data
            })

        },
        do_get_trader_active_for(){
            this.trader_page_get_views({qs:{trader_id:this.trader_id, view:'active'},data:{}}).then((r)=>{
                this.active_for = r.data
            })

        },
        do_get_trader_trades(){
          this.trader_page_get_views({qs:{trader_id:this.trader_id, view:'trades'},data:{}}).then((r)=>{
                this.trader_trades = r.data
            })
        },
        do_get_trader_similarity_groups(){

        },
        do_force_recalc_trades(){


            const ids_to_calc = this.miscalculated_trader_ids.join(',')
            this.force_recalc_trades({qs:{trade_ids:ids_to_calc},data:{}}).then((r)=>{
                this.do_get_trader_trades()
                this.do_force_get_trader_score(true)
            })

        }
    },
    computed:{
        ...mapState([]),
        miscalculated_trader_ids(){
            if(!this.trader_trades){return[]}
            let ids_to_calc = []
            for (let i = 0; i < this.trader_trades.trades.length; i++) {
                const trade = this.trader_trades.trades[i];
                if(trade.pair_standard_price == null){ids_to_calc.push(trade.id)}
                if(trade.amount_base == -1 ){ids_to_calc.push(trade.id)}
                
            }
            return ids_to_calc
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
table{
    font-size: 0.85em;
    white-space: nowrap;
}
.scores-table{
    font-size:1em;
    text-align:center;
}
.color-indicator{
    height:25px;
    width:35px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:9px;
    font-size:1.1em;
}
.buy_sell_summary{
    td,th{
        text-align:center;
    }
}
.trader-section{
    height: calc(100vh - 117px );
    overflow-y:scroll;
}
</style>