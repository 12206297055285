<template>
    <div>
        <v-container fluid v-if="similar_traders">
            <v-row>
                <v-col cols="12" md="12">
                    <table v-if="similar_traders">
                        <tr>
                            <th></th>
                            <th>Trader</th>
                            <th>similarity score</th>
                            <th>trader Scores</th>
                        </tr>
                        <tr v-for="(r,i) in similar_traders.relations" :key="r.id">
                            <td>
                                {{ i+1 }}.
                            </td>
                            <td>
                                Trader {{ r.trader.id }}
                                <a :href="`/trader/${r.trader.id}`"> -></a>
                            </td>
                            <td>
                                
                                <!-- {{ (100* r.relation_strength / similar_traders.self_trades).toFixed(2) }} -->
                                <v-progress-linear color="white" :value="(100* r.relation_strength / similar_traders.self_trades)"></v-progress-linear>
                            </td>
                            <td>
                                <div v-if="r.trader.score_buyer + r.trader.score_seller">
                                    {{ r.trader.score_buyer.toFixed(0) }} Buy /
                                    {{ r.trader.score_seller.toFixed(0) }} Sell

                                </div>
                                <div v-else>
                                    <a target="blank" :href="`/trader/${r.trader.id}`"> N/A Calc here</a>
                                </div>
                            </td>
            
                        </tr>
            
                    </table>
                    
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn class="ma-0"  elevation="2" small  block @click="do_trader_intel_calculate_similar_traders" >
                        more
                        <v-icon class="ma-0" small>mdi-plus</v-icon>
                    </v-btn>
                    
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn class="ma-0"  elevation="2" small  block @click="do_trader_intel_calculate_similar_traders" :disabled="loading.calc" >
                        (re) get
                        <v-progress-circular v-if="loading.calc" color="white" indeterminate size="20" ></v-progress-circular>
                        <v-icon v-else class="ma-0" small>mdi-refresh</v-icon>
                        
                        <small v-if="similar_traders.last_similarity_trader_check" >{{ similar_traders.last_similarity_trader_check_ago.split('.')[0] }} </small>
                    </v-btn>
                    
                </v-col>
            </v-row>
        </v-container>
        <!-- <pre>
            {{similar_traders}}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'intel-trader-related-traders',
    components:{
    },
    emits:[
    ],
    data(){return {
        similar_traders:undefined,
        max_n:10,
        nr_of_trades:1,
        loading:{
            list:false,
            calc:false,
        }
        }
    },
    props: { 
        traderId:{type:String,default:String},
        // nrOfTrades:{type:Number,default:1},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_trader_intel_get_similar_traders()
    
    },
    methods:{
        ...mapActions(['trader_intel_get_similar_traders','trader_intel_calculate_similar_traders']),
        do_trader_intel_get_similar_traders(){
            this.loading.list = true
            this.trader_intel_get_similar_traders({qs:{trader_id:this.traderId, max_n:this.max_n}}).then((r)=>{
                this.loading.list = false
                this.similar_traders = r.data
            })
        },
        do_trader_intel_calculate_similar_traders(){
            this.loading.calc = true
            this.trader_intel_calculate_similar_traders({qs:{trader_id:this.traderId, max_n:this.max_n}}).then((r)=>{
                this.loading.calc = false
                this.do_trader_intel_get_similar_traders()
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    text-align: center;
}
a{
    text-decoration: none;
    color:white;
}
</style>